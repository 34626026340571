<template>
  <Layout :tituloPagina="`Sistema | Aplicaciones | ${modo == 'nueva' ? 'Nueva' : 'Edición de'} aplicación`">
    <div class="row">
      <div class="col-xxl-3">
        <div class="user-sidebar">
          <div class="card">
            <div class="card-body p-0">
              <div class="user-profile-img">
                <img
                  src="@/assets/images/pattern-bg.jpg"
                  class="profile-img profile-foreground-img rounded-top"
                  style="height: 120px"
                />
                <div class="overlay-content rounded-top">
                  <div>
                    <div class="user-nav p-3">
                      <div class="d-flex justify-content-end">
                        <div class="dropdown">
                          <a
                            class="dropdown-toggle"
                            href="javascript: void(0);"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <eva-icon
                              name="more-horizontal-outline"
                              data-eva-width="20"
                              data-eva-height="20"
                              class="fill-white"
                            ></eva-icon>
                          </a>

                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <button class="dropdown-item" @click="seleccionarFoto()">
                                <eva-icon name="repeat" class="text-primary"></eva-icon>
                                {{foto == null ? 'Seleccionar ' : 'Cambiar '}} imagen
                              </button>
                            </li>
                            <li>
                              <button class="dropdown-item" @click="eliminarFoto()">
                                <eva-icon name="trash-2-outline" class="text-danger"></eva-icon>
                                Eliminar imagen
                              </button>
                            </li>
                            <li>
                              <button class="dropdown-item" @click="visibleImagen = true">
                                <eva-icon name="eye-outline" class="text-info"></eva-icon>
                                Ver imagen completa
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-n5 position-relative">
                <div class="text-center">
                  <div v-show="foto == null" class="auth-logo">
                    <img
                      :src="usuarioicon"
                      class="avatar-xl rounded-circle img-thumbnail cursor auth-logo-dark"
                      title="Seleccionar imagen"
                      @click="seleccionarFoto()"
                    />
                    <img
                      :src="usuarioiconblanco"
                      class="avatar-xl rounded-circle img-thumbnail cursor auth-logo-light"
                      title="Seleccionar imagen"
                      @click="seleccionarFoto()"
                    />
                  </div>
                  <div v-if="foto != null">
                    <img 
                      :src="foto" 
                      class="avatar-xl rounded-circle img-thumbnail cursor"
                      title="Ver imagen completa"
                      @click="visibleImagen = true"
                    />
                    <vue-easy-lightbox
                      :visible="visibleImagen"
                      :imgs="foto_visor"
                      @hide="visibleImagen = false"
                    ></vue-easy-lightbox>
                  </div>

                  <div class="mt-3">
                    <h5 class="m-3">
                      {{ aplicacion.nombre != '' ? aplicacion.nombre : 'Nombre de la aplicación' }}
                    </h5>
                  </div>
                  <div class="mt-4"></div>
                </div>
              </div>
              <div class="p-3 mt-3"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xxl-6">
        <div class="card">
          <div class="card-body">
            <div class="row pt-0">
              <div class="col-md-3" v-show="modo == 'edicion'">
                <div class="form-floating form-floating-custom mb-4">
                  <input
                    type="text"
                    class="form-control"
                    id="idAplicacion"
                    placeholder="#ID"
                    readonly
                    required
                    v-model="aplicacion.id"
                  />
                  <label for="idAplicacion">ID</label>
                  <div class="form-floating-icon">
                    <eva-icon name="hash-outline"></eva-icon>
                  </div>
                </div>
              </div>
              <div :class="modo == 'nueva' ? 'col-md-12' : 'col-md-9'">
                <div class="form-floating form-floating-custom mb-4">
                  <input
                    type="text"
                    class="form-control"
                    id="nombre"
                    name="nombre"
                    ref="nombre"
                    placeholder="Nombre de la aplicación"
                    required
                    maxlength="45" size="10" 
                    v-model="aplicacion.nombre"
                  />
                  <label for="nombre">Nombre de la aplicación</label>
                  <div class="form-floating-icon">
                    <eva-icon name="people-outline"></eva-icon>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-floating form-floating-custom mb-0 auth-pass-inputgroup" v-show="modo == 'edicion'">
              <input
                :type="type_access_token"
                class="form-control pe-6"
                :class="{'bg-white': $store.state.layout.layoutMode == 'light'}"
                id="accessToken"
                ref="accessToken"
                placeholder="Access Token"
                readonly
                v-model="aplicacion.access_token"
              />
              <div class="btn-group position-absolute h-100 end-0 top-0">
                <button 
                  type="button"
                  class="btn btn-link"
                  title="Detalles importantes del ACCESS TOKEN"
                  id="accessTokenAddon"
                  @click="mostrarMdlDetalleAccessToken()"
                >
                  <i class="mdi mdi-information-outline font-size-18 text-muted"></i>
                </button>
                <button 
                  type="button"
                  class="btn btn-link"
                  title="Copiar el ACCESS TOKEN en la papelera"
                  id="accessTokenAddon"
                  @click="copiarAccessToken()"
                >
                  <i class="mdi mdi-content-copy font-size-18 text-muted"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-link "
                  id="accessTokenAddon"
                  :title="type_access_token == 'text' ? 'Ocultar el ACCESS TOKEN' : 'Mostrar el ACCESS TOKEN'"
                  @click="type_access_token == 'text' ? type_access_token = 'password' : type_access_token ='text'"
                >
                  <i class="mdi font-size-18 text-muted" :class="type_access_token == 'text' ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"></i>
                </button>
              </div>
              
              <label for="accessToken">Access token</label>
              <div class="form-floating-icon">
                <eva-icon name="lock-outline"></eva-icon>
              </div>
            </div>
            <sub v-show="modo == 'edicion'">
              ** Asegúrate de guardar este token en un lugar seguro. <strong>Este código es esencial para autenticarte y 
              acceder a servicios protegidos</strong>. Evita compartirlo con terceros para mantener la seguridad de tu cuenta.
            </sub>

            <br v-show="modo == 'edicion'">
            <br v-show="modo == 'edicion'">

            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <h5>
                  Roles de la aplicación
                  <button class="btn btn-soft-dark btn-sm"
                    ref="mostrarRoles"
                    @click="mostrarMdlRoles()">
                    <i class="mdi mdi-plus-thick"></i>
                    Asignar
                  </button>
                </h5>
                <span v-for="rol in aplicacion.roles" :key="rol.id"
                  class="badge bg-success p-2 m-1 font-size-12 bg-gradient fw-semibold rounded" style="display: inline-block;">
                  {{rol.nombre}}
                  <a href="#" onclick="return false"
                    @click="quitarRol(rol.id)">
                    <i class="mdi mdi-close"></i>
                  </a>
                </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6" v-show="modo == 'edicion'">
                <h5>Estado de la aplicación</h5>
                <div class="form-floating form-floating-custom mb-4">
                  <select
                    class="form-select"
                    id="activo"
                    v-model="aplicacion.activo"
                  >
                    <option :value="1">Activo</option>
                    <option :value="0">Inactivo</option>
                  </select>
                  <label for="activo">Estado de la aplicación</label>
                  <div class="form-floating-icon">
                    <eva-icon :name="aplicacion.activo ? 'person-done-outline' : 'person-remove-outline'"></eva-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right pb-4">
          <div class="btn-group">
            <button class="btn btn-secondary"
              @click="cerrar()">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>
            <button
              class="btn btn-success"
              @click="modo == 'nueva' ? guardar() : actualizar()"
              :disabled="bandera_spinner"
            >
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              {{ modo == 'nueva' ? 'Guardar' : 'Actualizar' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <mdl-roles ref="mdlRoles" @rol:seleccionado="asignarRol($event)"></mdl-roles>
    <mdl-detalle-access-token ref="mdlDetalleAccessToken"></mdl-detalle-access-token>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import AplicacionSrv from "@/services/AplicacionSrv.js"
import usuarioicon from '@/assets/img/logoArgus.png'
import usuarioiconblanco from '@/assets/img/logoArgusBlanco.png'
import MdlRoles from './MdlRoles.vue'
import MdlDetalleAccessToken from './MdlDetalleAccessToken.vue'
import VueEasyLightbox from "vue-easy-lightbox"
import API from '@/API.js'

export default {
  name: 'EdicionAplicacion',
  components: {
    Layout,
    MdlRoles,
    MdlDetalleAccessToken,
    VueEasyLightbox,
  },
  props: ['id'],
  data() {
    return {
      modo: 'nueva',
      tk: localStorage.getItem('argusblack.token'),
      API: API,
      baseUrl: window.location.origin,
      usuarioicon: usuarioicon,
      usuarioiconblanco: usuarioiconblanco,
      foto_visor: null,
      foto: null,
      aplicacion: {
        id: null,
        id_foto: null,
        nombre: '',
        activo: 1,
        roles: [],
        foto: null,
        access_token: ''
      },
      roles: [],
      rol: {
        id: null,
        nombre: ''
      },
      bandera_spinner: false,
      visibleImagen: false,
      type_access_token: 'password',
    }
  },

  created: function() {
    var self = this

    // Carga del modo
    if (self.$route.path.indexOf('nueva') == -1) self.modo = 'edicion'

    // Carga de aplicación
    if (self.modo == 'edicion') self.cargarAplicacion()
  },

  watch: {
    id: function(newId, oldId) {
      var self = this
      if(newId !=  null) self.cargarAplicacion()
    }
  },

  methods: {
    actualizar: function() {
      var self = this

      self.bandera_spinner = true

      // Verificación de datos correctos
      if (!self.verificarDatosCorrectos()) return

      let aplicacion = Object.assign({}, self.aplicacion)
      delete aplicacion.access_token
      aplicacion.roles = aplicacion.roles.map(r => r.id)

      AplicacionSrv.actualizar(aplicacion).then(response => {
        iu.msg.success('Se actualizó correctamente')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    asignarRol(rol) {
      let res = this.aplicacion.roles.find(r => r.id == rol.id)
      
      // Si está asignado el rol, se evita volver a asignarlo
      if(res != undefined) return;

      this.aplicacion.roles.push(rol)
    },

    cargarAplicacion: function() {
      var self = this

      AplicacionSrv.aplicacionJSON(this.id).then(response => {
        self.aplicacion = response.data

        if(self.aplicacion.id_foto != null) {
          self.foto = self.API + '/aplicaciones/' + self.aplicacion.id + '/imagen?tipo_foto=256x256&_tk=' + self.tk + '&rnd=' + Math.random()
          self.foto_visor = self.API + '/aplicaciones/' + self.aplicacion.id + '/imagen?tipo_foto=original&_tk=' + self.tk + '&rnd=' + Math.random()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'Aplicación no encontrada'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.$router.go(-1)
    },

    copiarAccessToken: function() {
      var self = this,
          aux = document.createElement("input")

      aux.setAttribute("value", self.aplicacion.access_token)
      document.body.appendChild(aux)
      aux.select()
      document.execCommand("copy")
      document.body.removeChild(aux)

      iu.msg.success('El Access Token ha sido copiado correctamente.')
    },

    eliminarFoto: function() {
      var self = this
      self.foto = null
      self.foto_visor = null
      self.aplicacion.foto = null
      self.aplicacion.eliminar_imagen_antigua = true
    },

    guardar: function() {
      var self = this

      self.bandera_spinner = true

      if (!self.verificarDatosCorrectos()) {
        self.bandera_spinner = false
        return
      }

      let aplicacion = Object.assign({}, self.aplicacion)
      delete aplicacion.id
      delete aplicacion.id_foto
      aplicacion.roles = aplicacion.roles.map(r => r.id)

      AplicacionSrv.guardar(aplicacion).then(response => {
        iu.msg.success('La nueva aplicación se ha guardado correctamente.')
        var id = response.data

        self.modo = 'edicion'
        self.aplicacion.id = id

        self.$router.replace({ name: 'edicionAplicacion', params: { id: id } })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar la aplicación. Por favor, intenta de nuevo.'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    mostrarMdlDetalleAccessToken: function() {
      this.$refs.mdlDetalleAccessToken.mostrar()
    },

    mostrarMdlRoles() {
      this.$refs.mdlRoles.mostrar()
    },
    

    quitarRol(idRol) {
      if(this.aplicacion.roles.length == 1) {
        iu.msg.warning('No se puede eliminar el rol porque es el único asignado.')
        return
      }

      // Si el aplicacion tiene más de un rol, se procede a eliminar el rol
      let index = this.aplicacion.roles.findIndex(r => r.id == idRol)
      this.aplicacion.roles.splice(index, 1)
    },

    seleccionarFoto: function() {
      var self = this,
          inpFoto = document.createElement("input"),
          reader = new FileReader()

      inpFoto.setAttribute('type', 'file')
      inpFoto.click()

      reader.onload = function(e) {
        self.foto = this.result
        self.foto_visor = this.result
      }

      inpFoto.addEventListener('change', function(){
        self.aplicacion.foto = inpFoto.files[0]
        self.aplicacion.eliminar_imagen_antigua = true
        reader.readAsDataURL(self.aplicacion.foto)
      })
    },

    verificarDatosCorrectos: function() {
      var self = this

      if (self.aplicacion.nombre == '') {
        iu.msg.warning('Se requiere un nombre.')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return false
      }

      if (self.aplicacion.nombre.length > 45) {
        iu.msg.warning('El nombre de la aplicación no debe exceder los 45 caracteres.')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return false
      }

      if (self.aplicacion.roles.length == 0) {
        iu.msg.warning('Es necesario asignar al menos un rol')
        self.$refs.mostrarRoles.click()
        self.bandera_spinner = false
        return false
      }

      return true
    }
  }
}
</script>

<style scoped>
.avatar-xl {
  height: 10rem;
  width: 10rem;
}
.cursor{
  cursor: pointer;
}

.pe-6 {
  padding-right: 8.5rem !important;
}
</style>
<template>
  <div class="modal fade" ref="mdlDetalleAccessToken" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header bg-success">
          <h4 class="modal-title text-white">
            Detalles importantes
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul>
            <li>
              <strong>Verificación:</strong> 
              Asegúrate de que el token copiado sea exactamente el mismo que se muestra en la fuente original, 
              sin espacios adicionales al principio o al final.
            </li>
            <li>
              <strong>Seguridad:</strong> 
              El Access Token es una pieza crítica de información para autenticar tus solicitudes. Guárdalo en un 
              lugar seguro y no lo compartas con personas no autorizadas para evitar comprometer la 
              seguridad de tu cuenta.
            </li>
            <li>
              <strong>Uso del Token:</strong> 
              Utiliza el token copiado según las instrucciones proporcionadas por <a href="https://apiv1.argus.black/docs/api#/" target="_blank">Documentación de APIV1</a> para acceder a los recursos o 
              servicios necesarios.
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal';
export default {
  name: 'MdlDetalleAccessToken',
  methods: {
    mostrar() {
      var modal = new Modal(this.$refs.mdlDetalleAccessToken)
      modal.show()
    }
  }
}
</script>

<style scoped>

</style>